$padding-top: 54px;

html[data-theme="dark"] {
  --color-loader: #fff;
  --color-theme: #fff;
  --color-theme-hover: #3490ff;
  --color-theme-hover-lighter: lighten(--color-theme-hover, 30%);
  --color-white: #232323;
  --color-gray: gray;
  --color-light: #4b4b4b;
  --color-light-darker: #4b4b4b;
  --color-light-gray-blue: #e1e4e7;
  --color-red: #ed2929;
  --color-dark-grey: #d8d8d8;
  --color-dark: #f8f8f8;
  --color-green: #3daf55;
}

html[data-theme="light"] {
  --color-loader: #001731;
  --color-theme: #001731;
  --color-theme-hover: #0c60c7;
  --color-theme-hover-lighter: lighten(--color-theme-hover, 30%);
  --color-white: #fff;
  --color-gray: gray;
  --color-light: #ececec;
  --color-light-darker: #cfcfcf;
  --color-light-gray-blue: #e1e4e7;
  --color-red: #ed2929;
  --color-dark-grey: #3c3c3c;
  --color-dark: #202020;
  --color-green: #3daf55;
}

.loader > span {
  background-color: var(--color-loader) !important;
}

/* css */
html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
}

input {
  font-size: 1em;
  padding: 5px 10px;
  width: -webkit-fill-available;
  outline: none;
  border: 1px solid var(--color-light);
  border-radius: 3px;
  width: 280px;
}

input:hover {
  border: 1px solid var(--color-light);
}

input:focus {
  border: 1px solid var(--color-theme-hover);
}

form > label > p {
  margin: 10px 0 2px 0;
}

table {
  border: 1px solid;
  border-spacing: 0;
}

table th {
  border: 1px solid var(--color-theme);
  background-color: var(--color-theme);
  color: var(--color-white);
  padding: 4px 5px;
  text-align: left;
}

table td {
  border-bottom: 1px solid var(--color-light);
  border-right: 1px solid var(--color-light);
  padding: 3px 10px;
}

table .td {
  margin: auto;
}

.super-admin {
  //color: white;
  // background-color: var(--color-red);
  text-align: center;
  padding: 0px 0px;
}

.super-admin > svg {
  font-size: 2em;
}

.super-admin-checked {
  //background-color: var(--color-green);
  color: var(--color-green);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-light-darker);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-light-darker);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-light-darker);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: (--color-light-darker);
}

.flex-box {
  display: flex;
}

.error-message {
  color: var(--color-red);
}

.App {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--color-theme);
  background-color: var(--color-white);
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  font-size: 17px;
}

.App-logo {
  vertical-align: middle;
  color: var(--color-theme);
  float: left;
  padding: 12px 6px;
  width: 100px;
  transition: ease-in 0.25s;
}

.App-logo img {
  width: 100%;
}

.App-logo:hover {
  padding: 15px 6px 9px;
  cursor: pointer;
}

.App-header {
  width: 100%;
  position: fixed;
  display: flex;
  top: 0;
  z-index: 11;
  border-bottom: 2px solid var(--color-light);
  background: var(--color-white);
}

.App-menu {
  color: var(--color-theme);
  padding: 14px 14px 8px;
  transition: ease-in 0.2s;
}

.App-menu:hover {
  padding: 16px 14px 6px;
  color: var(--color-theme-hover);
  cursor: pointer;
}

.App-menu-name {
  width: fit-content;
  position: absolute;
  right: 50px;
  text-align: right;
  padding: 16px 14px 15px;
  transition: padding ease-in 0.2s;
}

.App-menu-name:hover {
  padding: 19px 14px 12px;
  color: var(--color-theme-hover);
  cursor: pointer;
}

.App-menu-search-bar-container {
  flex: 3;
  width: 100%;
  text-align: center;
  padding: 5px;
  margin: 0;
  border: 0;
  opacity: 0;
}

.App-menu-search-bar-container > input {
  width: 100%;
  padding: 7px 8px 8px;
  margin: 0;
  border: 1px solid var(--color-light);
  font-size: 1.1em;
  color: var(--color-theme);
  font-weight: 400;
  max-width: 500px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 3px 0 0 3px;
}

.App-menu-search-bar-container > input:focus {
  border: 1px solid var(--color-light-darker);
  outline: none;
}

.App-menu-login-screen {
  position: absolute;
  background: var(--color-white);
  top: 54px;
  right: 0;
  padding: 20px 40px 40px;
  border: 2px solid var(--color-light);
  border-top: 0;
  border-right: 0;
  z-index: 10;
  transition: ease-in 0.2s;
  height: calc(100vh - 116px);
}

.login-screen-close {
  right: -500px;
}

.App-menu-profile-screen {
  position: absolute;
  background: var(--color-white);
  top: 54px;
  right: 0;
  border: 2px solid var(--color-light);
  border-top: 0;
  border-right: 0;
  z-index: 10;
  transition: ease-in 0.2s;
}

.profile-screen-close {
  right: -500px;
}

.login-screen-close {
  right: -500px;
}

.App-menu-profile-screen {
  position: absolute;
  background: var(--color-white);
  top: 54px;
  right: 0;
  border: 2px solid var(--color-light);
  border-top: 0;
  border-right: 0;
  z-index: 10;
  transition: ease-in 0.2s;
}

.login-screen-close {
  right: -500px;
}

.App-menu-profile-screen {
  position: absolute;
  background: var(--color-white);
  top: 54px;
  right: 0;
  border: 2px solid var(--color-light);
  border-top: 0;
  border-right: 0;
  z-index: 10;
  transition: ease-in 0.2s;
}

.profile-screen-close {
  right: -500px;
}

.App-menu-profile {
  width: 30px;
  height: 30px;
  margin: 11px;
  overflow: hidden;
  border-radius: 100%;
  transition: ease-in 0.2s;
  float: right;
}

.App-menu-profile:hover {
  cursor: pointer;
  margin: 14px 11px 8px;
}

.App-menu-profile img {
  height: 100%;
  width: 100%;
}

.search-bar-icon {
  color: var(--color-light);
  border: 1px solid var(--color-light);
  border-left: 0;
  padding: 7px;
  vertical-align: bottom;
}

.App-content {
  background: var(--color-white);
  margin-top: $padding-top;
  margin-left: 252px;
  width: calc(100% - 272px);
  padding: 10px;
  transition: ease-in 0.2s;
  height: calc(100vh - 74px);
  overflow: auto;
}

.content-full {
  margin-left: 52px;
  width: calc(100% - 72px);
  transition: ease-in 0.2s;
}

.App h1 {
  font-size: 1.4em;
  padding: 0;
  margin: 5px 0;
}

.navbar {
  height: 100vh;
  padding-top: $padding-top;
  position: fixed;
  margin-left: 0;
  border-left: 0;
  border-right: 2px solid var(--color-light);
  width: 250px;
  transition: linear 0.2s;
  background-color: var(--color-white);
  z-index: 1;
}

.navbar-close {
  width: 50px;
}

.navbar-close .navbar-title {
  opacity: 0;
}

.navbar ul {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar li {
  float: left;
  width: 100%;
  list-style-type: none;
}

.navbar li > a {
  float: left;
  width: calc(100% - 20px);
  padding: 10px;
  overflow: hidden;
  display: flex;
}

.navbar-icon,
.navbar-title {
  color: var(--color-theme);
}

.navbar li:hover,
.navbar li.active {
  cursor: pointer;
  background: var(--color-light);

  .navbar-icon,
  .navbar-title {
    color: var(--color-theme-hover);
  }
}

.navbar-icon {
  margin: 0 4px;
}

.navbar-title {
  width: calc(100% - 40px);
  padding: 2px 0 2px 10px;
  white-space: nowrap;
  transition: opacity linear 0.2s;
}

@media (max-width: 768px) {
  .App-header {
    display: unset;
  }

  .App-menu {
    vertical-align: middle;
    float: right;
  }

  .App-content {
    margin-left: -356px;
    width: calc(100% - 20px);
  }

  .content-full {
    margin-left: 0px;
    width: calc(100% - 20px);
  }

  .navbar {
    top: 0;
    left: unset;
    right: 0;
    width: 354px;
    border-left: 2px solid var(--color-light);
    border-right: 0;
    background-color: var(--color-theme);
    color: var(--color-white);
  }

  .navbar-icon,
  .navbar-title {
    color: var(--color-white);
  }

  .navbar-close {
    right: -500px;
  }

  .App-menu-search-bar-container {
    display: none;
  }
}

.main-search-container {
  width: 100%;
  max-width: 700px;
}

.main-search-header {
  float: left;
  width: 100%;
}

.main-search {
  float: left;
  padding: 7px 10px;
  font-size: 1.25em;
  width: calc(100% - 24px);
  color: var(--color-theme-hover);
  border: 1px solid var(--color-light-darker);
}

.main-search:active,
.main-search:focus {
  border: 1px solid var(--color-theme-hover);
  outline: none;
}

.main-search-btn {
  float: left;
  padding: 7px 10px;
  margin-left: 20px;
  font-size: 1.25em;
  width: 100px;
  background: none;
  border: 1px solid var(--color-theme);
  color: var(--color-theme);
}

.main-search-btn:hover {
  cursor: pointer;
  color: var(--color-white);
  border: 1px solid var(--color-theme);
  background: var(--color-theme);
}

.main-search-btn:active,
.main-search-btn:focus {
  border: 1px solid var(--color-theme-hover-lighter);
  outline: none;
}

.main-search-results {
  float: left;
  width: 100%;
  max-width: 700px;
}

.main-search-results ul {
  margin: 0;
  padding: 10px 5px;
  list-style-type: none;
}

.main-search-results li {
  margin: 0;
  padding: 2px 0px;
  list-style-type: none;
}

.video-wrapper {
  max-width: 1200px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--color-light);
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  transition: linear 2s;
}

.highlighted {
  color: var(--color-red);
  font-weight: bold;
}

.video-player-placeholder {
  box-shadow: 0 0 5px var(--color-light);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transition: linear 2s;
  width: 100%;
  height: 100%;
}

.channel-container {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.channel-link {
  float: left;
  width: calc(100% - 10px);
  background: var(--color-light);
  color: var(--color-dark-grey);
  transition: all 0.2s;
  margin: 5px;
}

.channel-link:hover {
  text-decoration: underline;
}

.channel-link:hover > h3 {
  color: var(--color-dark);
}

.channel-link > h3 {
  color: var(--color-dark-grey);
  margin: 10px;
  transition: all 0.3s;
}

.live-link {
  background-color: var(--color-red);
  color: var(--color-white);
}

.live-link > h3 {
  color: var(--color-white);
}

.live-link:hover {
  text-decoration: none;
}

.live-link:hover > h3 {
  color: var(--color-white);
}

.video-tiles-container {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% - 0px);
  overflow: hidden;
}

.video-player-placeholder.hide {
  opacity: 0;
  pointer-events: none;
}

.video-arrow-right {
  position: absolute;
  top: calc(50% - 10px);
  right: 0px;
  background: var(--color-theme);
  opacity: 50%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  transition: all 0.3s;
}

.video-arrow-right:hover {
  cursor: pointer;
  opacity: 100%;
}

.video-arrow-right::after {
  content: "";
  position: absolute;
  top: calc(50% - 13px);
  right: calc(50% - 10px);
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid var(--color-white);
}

.video-tile {
  flex: 1 0 100%;
  margin: 5px;
  max-width: calc(20% - 10px);
  color: var(--color-theme);
  position: relative;
}

.video-tile:hover img {
  box-shadow: 1px 2px 5px var(--color-dark-grey);
}

.video-container {
  width: 100%;
}

.video-img-container {
  position: relative;
  padding-top: 56.25%;
  background: var(--color-light);
}

.video-img-container img {
  width: 100%;
  border: outset 1px var(--color-light);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.video-title-h1 {
  font-weight: 500;
}

.video-title {
  font-size: 1.1em;
  font-weight: 500;
}

.video-title-container {
  margin: 10px 0;
  font-size: 1em;
  font-weight: 500;
}

.video-title-live {
  text-decoration: none;
  outline: none;
  color: var(--color-white);
  padding: 2px 8px;
  margin: 0 10px;
  border-radius: 3px;
  background: var(--color-red);
  box-shadow: 0px 0px 5px var(--color-theme);
  position: absolute;
  top: 10px;
  right: -3px;
}

.video-details {
  display: block;
  font-size: 0.9em;
  font-weight: normal;
  color: var(--color-gray);
}

.video-title-author {
  display: block;
  font-size: 0.9em;
  font-weight: normal;
  color: var(--color-gray);
}

@media (max-width: 768px) {
  .channel-link {
    width: calc(100%);
    margin: 5px 0;
  }

  .video-tiles-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .video-arrow-right {
    display: none;
  }

  .video-tile {
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
  }
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

.badge-live {
  display: inline-flex;
  background: var(--color-red);
  font-size: 90%;
  color: var(--color-white);
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-left: 0.5rem;
  align-items: center;
}

.badge-live svg {
  margin-left: 0.1rem;
}

.badge-live > svg {
  margin: 0 5px 0 0;
}

.badge-live > span {
  margin: 0 2px 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.profile-menu-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-menu-wrapper a,
.profile-menu-wrapper .logout {
  width: 200px;
  padding: 12px 20px;
}

.profile-menu-wrapper a:hover,
.profile-menu-wrapper .logout:hover {
  cursor: pointer;
  background-color: var(--color-light);
  color: var(--color-theme-hover);
}

.profile-menu-title {
  vertical-align: super;
  padding: 5px 10px;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  width: 304px;
}
.login-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.video-details-row-container {
  display: flex;
}

.video-details-container {
  flex: 4;
  font-size: 1.1em;
}

.video-actions-panel {
  display: flex;
  flex-wrap: wrap;
}

.video-actions-panel button {
  background: none;
  color: var(--color-theme);
  font-family: "Segoe Ui";
  border: none;
  padding: 10px;
}

.video-actions-panel button:hover {
  cursor: pointer;
  background: var(--color-light);
  color: var(--color-theme-hover);
}

.video-actions-panel button > span {
  padding: 0 5px;
  font-size: 1.1em;
}

.video-actions-panel button > svg {
  font-size: 1.5em;
}

#disqus_recommendations {
  margin-top: 0 !important;
}

.hide {
  display: none;
}

.button {
  border: 1px solid;
  background: var(--color-theme);
  color: var(--color-white);
  padding: 5px 10px;
  width: fit-content;
  border-radius: 2px;
}

.button:hover {
  cursor: pointer;
  background: var(--color-theme-hover);
}

.button-link {
  border: 0px solid;
  color: var(--color-theme);
  padding: 5px 10px;
  width: fit-content;
  border-radius: 2px;
}

.button-link:hover {
  cursor: pointer;
  color: var(--color-theme-hover);
  text-decoration: underline;
}

.loader-container {
  flex: 0 0 100%;
}
.loader-container > span {
  width: 100% !important;
}

.channel-loading-container h1 {
  background-color: var(--color-light-gray-blue);
  color: var(--color-light);
  padding: 10px;
  border-radius: 4px;
}

.channel-loading-background {
  background-color: var(--color-light);
  margin-top: 10px;
  height: 250px;
  width: 100%;
  border-radius: 4px;
}

.channel-loading-tabs {
  display: none;
  margin-top: 10px;
  height: 50px;
  width: 100%;
}

.channel-loading-tab {
  margin: 0;
  flex: 1;
  height: 50px;
  border-left: 2px solid var(--color-light);
}

.channel-loading-video-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
}

.channel-loading-video {
  flex-grow: 1;
  width: 20%;
  background-color: var(--color-white);
}

.channel-loading-video-thumbnail {
  width: calc(100% - 20px);
  height: 200px;
  background-color: var(--color-light-gray-blue);
  border-radius: 4px;
  margin: 10px;
}

.channel-loading-video-details {
  width: calc(90% - 70px);
  height: 30px;
  background-color: var(--color-light);
  margin: 10px;
  margin-left: 60px;
  border-radius: 4px;
}

.channel-loading-video .shorter {
  width: calc(80% - 70px);
}

.channel-loading-video .circle {
  width: 40px;
  height: 40px;
  background-color: var(--color-light);
  border-radius: 100%;
  margin: 0 10px;
  float: left;
}

@media (max-width: 1250px) {
  .channel-loading-video {
    width: 33%;
  }
}

@media (max-width: 1000px) {
  .channel-loading-video {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .channel-loading-video {
    width: 100%;
  }
}

.channel-loading-container h1 {
  background-color: var(--color-light-gray-blue);
  color: var(--color-light);
  padding: 10px;
  border-radius: 4px;
}

.channel-loading-background {
  background-color: var(--color-light);
  margin-top: 10px;
  height: 250px;
  width: 100%;
  border-radius: 4px;
}

.channel-loading-tabs {
  display: none;
  margin-top: 10px;
  height: 50px;
  width: 100%;
}

.channel-loading-tab {
  margin: 0;
  flex: 1;
  height: 50px;
  border-left: 2px solid var(--color-light);
}

.channel-loading-video-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
}

.channel-loading-video {
  flex-grow: 1;
  width: 20%;
  background-color: var(--color-white);
}

.channel-loading-video-thumbnail {
  width: calc(100% - 20px);
  height: 200px;
  background-color: var(--color-light-gray-blue);
  border-radius: 4px;
  margin: 10px;
}

.channel-loading-video-details {
  width: calc(90% - 70px);
  height: 30px;
  background-color: var(--color-light);
  margin: 10px;
  margin-left: 60px;
  border-radius: 4px;
}

.channel-loading-video .shorter {
  width: calc(80% - 70px);
}

.channel-loading-video .circle {
  width: 40px;
  height: 40px;
  background-color: var(--color-light);
  border-radius: 100%;
  margin: 0 10px;
  float: left;
}

@media (max-width: 1250px) {
  .channel-loading-video {
    width: 33%;
  }
}

@media (max-width: 1000px) {
  .channel-loading-video {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .channel-loading-video {
    width: 100%;
  }
}

.error-message {
  color: var(--color-red);
}

.bible-popout {
  //max-width: 600px;
  //width: 100%;
  //padding: 0px;
  margin-top: 54px;
  right: 0;
  top: 0;
  height: calc(100vh - 54px);
  display: flex;
  transition: ease-in 0.2s;
  background-color: var(--color-white);
}

.bible-popout-inner {
  padding: 20px;
  width: 558px;
  height: calc(100% - 40px);
  border-left: 2px solid var(--color-light);
  overflow: hidden;
  flex: 1;
}

.bible-popout-close .bible-popout-inner {
  // padding: 20px;
  // width: 558px;
  // height: calc(100% - 40px);
  // border-left: 2px solid var(--color-light);
  // overflow: hidden;
  flex: 0;
}

@media (max-width: 768px) {
  .bible-popout {
    position: absolute;
    display: flow;
    width: 100%;
  }

  .bible-popout-inner {
    width: calc(100% - 40px);
    flex: none;
  }
}

.bible-popout-close {
  width: 0;
  overflow: hidden;
}

.bible-search-header {
  float: left;
  width: 100%;
}

.bible-search-header:hover {
  cursor: pointer;
  color: var(--color-theme-hover);
  text-decoration: underline;
}

.special-text{
  text-align: center;
  font-style: italic;
  font-weight: bold;
}

.bible-verse-highlight {
  color: var(--color-red);
}

.bible-verse {
  scroll-margin-top: 60px;
}

.bible-verse-default {
  color: var(--color-red);
  background: var(--color-light);
  display: block;
  width: 100%;
  height: 30px;
}

.bible-popout-header {
  width: 100%;
  height: 40px;
  position: relative;
  text-wrap: nowrap;
}

.bible-popout-header h1 {
  font-size: 1.7em;
  overflow: hidden;
}

.bible-popout-close-button {
  position: absolute;
  color: var(--color-dark);
  top: 5px;
  right: 5px;
}

.bible-popout-close-button:hover {
  cursor: pointer;
  color: var(--color-theme-hover);
}

.bible-popout-verse-container {
  height: calc(100vh - 170px);
  overflow: auto;
}

@media (max-width: 768px) {
  .bible-popout-verse-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.verse-bold {
  font-size: 0.8em;
}

.scroll-arrow {
  position: fixed;
  bottom: 10px;
  right: 30px;
  color: var(--color-light-darker);
}

.scroll-arrow:hover {
  cursor: pointer;
  color: var(--color-theme-hover);
}

.content-full-bible-popout .scroll-arrow {
  right: 630px;
}

@media (max-width: 768px) {
  .scroll-arrow {
    display: none;
  }
}

.bible-search-container {
  scroll-margin-top: 20px;
}

.profile-pic {
  max-width: 200px;
  max-height: 200px;
  border-radius: 100%;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.pic-small {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.table-users {
}

@media (max-width: 768px) {
  .table-users {
    display: block;
    width: 100%;
    max-width: 100%;
    table-layout:auto;
    overflow-wrap:normal;
    overflow-x: scroll;
  }
  .th{
    display: block;
  }
  .hidden-mobile {
    display: none;
  }
}

.form-popout {
  margin-top: 54px;
  right: 0;
  top: 0;
  height: calc(100vh - 54px);
  position: absolute;
  transition: ease-in 0.2s;
  background-color: var(--color-white);
  width: 100%;
  max-width: 558px;
}
.form-popout-inner {
  padding: 20px;
  height: calc(100% - 40px);
  border-left: 2px solid var(--color-light);
  overflow: hidden;
  flex: 1;
}

.form-popout-close {
  right: -750px;
}
